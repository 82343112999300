/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, runInAction } from 'mobx';
import RouterStore from './RouterStore';
import ErrorStore from './ErrorStore';
import cuid from 'cuid';
import { Project, ProjectState, Package, PackageState, ProjectKeywordRenameModel, PackageLine } from '../models';
import { ProjectsService } from '../services';
import { PackagesRequest, PackageLinesResponse, PackageStateResult, 
    PackageChange, IotaSessionChange, TestProjectChange } from '../types';
import { PushClient } from '../services';
import { filter, map } from 'rxjs/operators';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { ProjectPagesNavigation } from '../../project_management/routes';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { message } from 'antd';


export default class ProjectsStore {
    @observable
    projects: Project[] = [];

    @observable
    projectsStates? = observable.map();

    @observable
    lockNewProjectsSubmitting: boolean = false;

    @observable
    isPushServiceConnected: boolean = true;

    @observable
    busyPackages: string[] = [];

    packageChanges: Observable<PackageChange>;

    sessionChanges: Observable<IotaSessionChange>;

    testProjectChanges: Observable<TestProjectChange>;

    currentUserPermissions: string[];

    private packagesCache = {};

    constructor(public service: ProjectsService, private router: RouterStore, private errorStore: ErrorStore) {
        const pushClient = new PushClient();
        this.loadProjects().catch(error => this.errorStore.addBasicError(error));
        
        PushClient.ConnectionStatusSubject.subscribe((connected) => {
            runInAction(() => this.isPushServiceConnected = connected);
        }); 
        // Load project if needed
        // eslint-disable-next-line max-len
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const filterByReadyState = filter<any>(p => p.state === PackageStateResult.Ready || p.state === PackageStateResult.Broken);
        const filterByImportingState = filter<any>(p => p.state === PackageStateResult.Importing);
        const filterByNewProject = filter<any>(p => !this.projects.find(prj => prj.id === p.projectId));
        const filterPkgsByExistentProject = filter<any>(p => !!this.projects.find(prj => prj.id === p.projectId));
        const filterProjByExistentProject = filter<any>(p => !!this.projects.find(prj => prj.id === p.id));

        const pkg = pushClient.createPackageListener().publish();
        pkg.pipe(filterByReadyState, filterByNewProject).subscribe(this.loadProject.bind(this));

        // Unlock project if needed
        pkg.pipe(filterByReadyState, filterPkgsByExistentProject).subscribe(this.unlockProject.bind(this));

        // Lock project if needed
        pkg.pipe(filterByImportingState, filterPkgsByExistentProject).subscribe(this.lockProject.bind(this));

        this.packageChanges = pkg.pipe(filterPkgsByExistentProject, map((p) => {
            const state = p.state === PackageStateResult.Ready ? PackageState.Ready : p.state === PackageStateResult.Broken ? PackageState.Broken : PackageState.Busy;
            return {projectId : p.projectId, id: p.id || p._id, state: state, fileName: p.fileName, 
                filePath: p.filePath, userTags: p.userTags, source: p.source, uploadDate: p.uploadedTime, indexDate: p.indexDate, error: p.error, featureFlags: p.featureFlags,
                isProtected: p.isProtected}; 
        }));
        pkg.connect();

        const prjObs = pushClient.createProjectListener().publish();
        const sessionObs = pushClient.createSessionListener().publish();
        this.sessionChanges = sessionObs.map( s => {
            return  {
                id: s.id, packageId: s.packageId, projectId: s.projectId, runtimeSessionId: s.runtimeSessionId, 
                created: s.created, updated: s.updated, applicationDefinitionId: s.applicationDefinitionId
            };
        });
        sessionObs.connect();

        const filterByAddedProject = filter<any>(p => !this.projects.find(prj => prj.id === p.id));
        prjObs.pipe(filterByAddedProject).subscribe(this.loadProject.bind(this));
        prjObs.pipe(filterProjByExistentProject, map(r => this.projects.find(p => p.id === r.id)))
            .subscribe(this.reloadProject.bind(this));
        prjObs.connect();

        const testProjectObs = pushClient.createTestProjectsListener().publish();
        this.testProjectChanges = testProjectObs.map(tp => {
            return  {
                id: tp.id, 
                isRunning: tp.isRunning, 
                projectId: tp.projectId, 
                lastRunFuzzy: tp.lastRunFuzzy, 
                lastRunId: tp.lastRunId, 
                lastRunTime: tp.lastRunTime, 
                lastRunError: tp.lastRunError
            };
        });
        testProjectObs.connect();
    }

    @action.bound
    setIsPushServiceConnected(connected: boolean) {
        this.isPushServiceConnected = connected;
    }

    @action.bound
    setLockNewProjectsSubmitting(lock: boolean) {
        this.lockNewProjectsSubmitting = lock;
    }

    @action
    async addProject(project: Partial<Project>) {
        this.setLockNewProjectsSubmitting(true);
        const proj = new Project(cuid(), project.title!, project.keywords!, project.type!, project.color, undefined, project.featureFlags || undefined);
        var resp = await this.service.addProject(proj);
        this.setLockNewProjectsSubmitting(false);
        resp.map(() => {            
            this.router.pushToHistory(ProjectPagesNavigation.ProjectsPage);
        });
    }

    @action
    async updateProject(
        projectId: string,
        name: string,
        keywords: string[],
        color: string,
        featureFlags: string | null,
        smartIndexSettings: string | null
    ) {
        var resp = await this.service.updateProject(projectId, name, keywords, color, featureFlags, smartIndexSettings);
        await resp.asyncMap(async () => await this.loadProjects());
        const prj = this.projects.find(p => p.id === projectId)!;
        await this.getPackages(prj);
    }

    @action.bound
    async deleteProject(projectId: string) {
        var resp = await this.service.deleteProject(projectId);
        await resp.asyncMap(async () => {
            await this.loadProjects();
            this.router.pushToHistory(ProjectPagesNavigation.ProjectsPage);
        });
    }

    @action
    async updateProjectMetadata(project: Project, renameList: ProjectKeywordRenameModel[]) {
        try {
            await this.service.updateProjectMetadata(project, renameList);
            message.success('Project key labels updated successfully');
        } catch (ex) {
            console.error(ex);
        }
    }

    @action
    async loadProjects() {
        const res = await this.service.getProjectsForCurrentUser(PackageStateResult.Importing);
        const projects = res.map(d => {
            const proj = new Project(d.id, d.name, d.keywords, d.type, d.color, d.tagsVersion, undefined, d.featureFlags, d.smartIndexSettings);
            return proj;
        });
        if (!projects.length && !hasPermission(this.currentUserPermissions, AppPermissions.CanAddEditDeleteAssignProject)) {
            message.warning('There are no projects available and no permissions to create one', 0);
        }
        
        runInAction(() => {
            res.forEach(p => {
                const notReady = p.packages!.find(pk => pk.state !== PackageStateResult.Ready && pk.state !== PackageStateResult.Broken);
                if (notReady) {
                    const proj = projects.find(pr => pr.id === notReady.projectId)!;
                    proj.state = ProjectState.Locked;
                    this.projectsStates?.set(proj.id, proj.state);
                }
            });

            this.projects = projects;
        });
    }

    @action
    async commitProject(project: Project) {
        project.state = ProjectState.Processing;
        this.projectsStates?.set(project.id, project.state);

        const groupedLabels = project.currentLabels;
                
        const packageIds = _.flatten(project.dirtyPackages.map(p => ({ id: p.id })));
        await this.service.commitLabelsProject({ 
            projectId: project.id, 
            labels: groupedLabels, 
            namedEntities: [],
            packages: packageIds 
        });
        project.dirtyPackages.forEach(x => { 
            this.clearPackageCache(x); x.resetAllChanges(); 
        });        

        runInAction(() => {
            this.projectsStates?.set(project.id, project.state);
        });
    }    

    @action
    async getPackages(project: Project) {
        try {
            if (project.isPackagesLoaded) {
                return;
            }

            // const result = await Promise.all([this.getReadyPackages(project), 
            //     this.getImportingPackages(project)]);
    
            // const readyResult = result[0];
            // const importingPackages = result[1];

            // runInAction(() => {
            //     project.setupAnchors(readyResult.labels || []);
            //     project.packages = readyResult.packages;
            //     project.importingPackages = importingPackages;
            //     project.packageIds = readyResult.packages.map(p => p.id);
            //     project.isPackagesLoaded = true;
            // });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async searchInPackages(project: Project, request: PackagesRequest, pkg?: Package | undefined): Promise<PackageLinesResponse> {
        const result = await this.service.getPackageLines(project, request, pkg);
        const lines = result.lines.map(element => {
            let cache = this.packagesCache[element.pkg.id];
            if (!cache) {
                cache = this.packagesCache[element.pkg.id] = observable.map();
            }

            const key = `${element.pkg.id}-${element.rowId}`;
            if (cache.has(key)) {
                return cache.get(key) as PackageLine;
            } else {
                cache.set(key, element);
            }

            return element;
        });

        project.addPackageLines(lines);

        result.lines = lines;
        return result;
    }

    async savePackageProblemMessage(packageId: string, text: string, page: number) {
        const resp = await this.service.savePackageProblemMessage(packageId, text, page);
        return resp.unwrapOr(undefined);
    }

    handleDownload(id: string, fileType?: 'pdf'| 'apkg') {
        this.service.handleDownload(id, fileType);
    }

    async fetchTags(id?: string): Promise<string[]> {
        return await this.service.getProjectTags(id);
    }

    setCurrentUserRoles(roles: string[]) {
        this.currentUserPermissions = roles;
    }

    @action
    private clearPackageCache(pkg: Package) {
        if (this.packagesCache[pkg.id]) {
            delete this.packagesCache[pkg.id];
        }
    }

    @action
    private async loadProject(project: Project) {
        console.log('Loading project...');

        try {
            const projects = await this.service.getProjectsForCurrentUser(null);
            if (!projects.find(x => x.id === project.id)) {
                return;
            }

            const result = await this.service.getProject(project.id);            

            if (result) {
                const proj = new Project(
                    result.id,
                    result.name,
                    result.keywords,
                    result.type,
                    result.color,
                    undefined,
                    undefined,
                    result.featureFlags,
                    result.smartIndexSettings
                );
                runInAction(() => {
                    this.projects.push(proj);
                });
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    @action
    private async unlockProject(pkg: any) {
        try {

            runInAction(() => {
                this.busyPackages = this.busyPackages.filter(p => p !== pkg.id);
            });

            if (this.busyPackages.length !== 0) {
                return;
            }

            console.log('Unlocking project...');

            const prj = this.projects.find(p => p.id === pkg.projectId)!;
            this.packagesCache = {};

            runInAction(() => {
                prj.state = ProjectState.Ready;
                this.projectsStates?.set(prj.id, prj.state);
                this.reloadProject(prj);
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    @action
    private async lockProject(pkg: any) {
        try {
            console.log('Locking project...');

            const prj = this.projects.find(p => p.id === pkg.projectId)!;
            prj.state = ProjectState.Locked;
            this.projectsStates?.set(prj.id, prj.state);

            runInAction(() => {
                this.busyPackages.push(pkg.id);
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    @action
    private async reloadProject(proj: Project) {
        const index = this.projects.indexOf(proj);
        if (index === -1) {
            return; 
        }

        if (proj.isPackagesLoaded) {
            for (const p of proj.packages) {
                delete this.packagesCache[p.id];
            }
        }

        const projCopy = this.projects.slice();

        const res = await this.service.getProject(proj.id);

        if (res) {
            const newProj = new Project(
                res.id,
                res.name,
                res.keywords,
                res.type,
                res.color,
                res.tagsVersion,
                undefined,
                res.featureFlags,
                res.smartIndexSettings
            );
            
            runInAction(() => {
                if (this.projects.map(x => x.id).indexOf(newProj.id) > -1) {
                    projCopy[index] = newProj;
                    this.projects = projCopy;
                }
            });
        }
    }
}