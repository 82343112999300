import { Alert, Button, Layout, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import LayoutHeader from '../../../components/LayoutHeader';
import { TestProjectBaseline, TestProjectDetails, TestProjectPackagesEditDialog, TestProjectRuns, TestProjectTopicsEditDialog } from '.';
import { TestProjectDashboardStore, TestProjectWizardStore } from '../stores';

type Props = {
    store: TestProjectDashboardStore;
    wizardStore: TestProjectWizardStore
};

const TestProjectDashboardPage: React.FC<Props> = ({store, wizardStore}) => {
    React.useEffect(() => {
        if (store.currentProject && store.currentTestProjectId) {
            store.loadTestProject();
        }

        if (!store.users || store.users.length === 0) {
            store.loadUsers();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject, store.currentTestProjectId]);

    const getErrorsContent = () => {
        if (store.testProject == null || store.testProject.errors == null || store.testProject.errors.length === 0) {
            return null;
        }

        return (
            <Alert
                message="Error"
                description={store.testProject.errors.join('\n')}
                type="error"
                showIcon
                style={{marginBottom: '24px', whiteSpace: 'pre-wrap'}}
            />
        );
    };

    return (
        <Layout className="screen-size test-project-dashboard" style={{...{height: '100%', background: 'white'}}}>
            <LayoutHeader  
                title={store.testProject?.name || <Skeleton active paragraph={{rows: 0}} /> }
                buttons={[
                    <Button 
                        key="test-projects-dashboard-go-bacl" 
                        data-id="button-go-to-list" 
                        className="light"
                        size="large"
                        onClick={store.goBackToList}
                    >
                        Go back
                    </Button>,
                    <Button 
                        key="test-projects-dashboard-run" 
                        data-id="button-run-test-project" 
                        type="primary" 
                        size="large"
                        onClick={store.runTestProject}
                        disabled={store.projectIsLocked || !store.testProject?.hasValidBaselines}
                        loading={store.projectIsLocked}
                    >
                        Run test
                    </Button>
                ]}
            />
            {getErrorsContent()}
            <Layout>
                <Layout.Sider theme="light">
                    <TestProjectDetails store={store} />
                </Layout.Sider>
                <Layout.Content className="test-project-dashboard-content">
                    <TestProjectPackagesEditDialog store={wizardStore} dashboardStore={store} />
                    <TestProjectTopicsEditDialog store={wizardStore} dashboardStore={store} />
                    <TestProjectBaseline store={store} wizardStore={wizardStore} />
                    <TestProjectRuns store={store} />
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default observer(TestProjectDashboardPage);