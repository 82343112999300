import * as React from 'react';
import { Upload, message, Select, Form, Collapse, Input, Checkbox } from 'antd';
import { UploadChangeParam,  } from 'antd/lib/upload';
import { inject, observer } from 'mobx-react';
import { UploadStores } from '../stores';
import { STORE_PROJECT_UPLOAD } from '../constants';
import { reaction, Lambda } from 'mobx';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import { STORE_UPLOADED_PACKAGES } from '../../project_management/constants';
import { ProjectStores } from '../../project_management/stores';
import { FormInstance } from 'antd/lib/form';
import { AlphaConfigurations } from '../../common/constants';
import { AlphaConfig } from '../../administration/models/AlphaConfig';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { importFeatureFlags, resetFormFeatureFlags, setProjectFeatureFlags } from '../../common/FeatureFlagsHelpers';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

const Dragger = Upload.Dragger;
const { Option } = Select;
const FormItem = Form.Item;

type UploadDocumentsProps = UploadStores & ProjectStores & {
    alphaConfigs: AlphaConfig[];
    form?: FormInstance
};

type State = {
    fileList: UploadFile[];
    featureFlagFileList: UploadFile[];
    overrideFeatureFlags: boolean;
    overridePagesFeatureFlags: boolean;
    overrideTextFeatureFlags: boolean
};

class UploadDocuments extends React.Component<UploadDocumentsProps, State> {
    private action: string;
    private readonly disposer: Lambda;
    private form: FormInstance;
    constructor(props: UploadDocumentsProps) {
        super(props);     
        this.init();
        this.resetFeatureFlags = this.resetFeatureFlags.bind(this);
        this.initProjectFeatureFlags = this.initProjectFeatureFlags.bind(this);

        if (this.props.uploadedPackagesUI) {
            const r1 = reaction(() => this.props.projectUploadUI!.currentProject, () => {
                this.init();
            });        

            const r2 = reaction(() => this.props.uploadedPackagesUI!.uploadDocsDialogVisible, (p) => {
                if (!p) {
                    this.setState({fileList: [], featureFlagFileList: []});
                    this.props.projectUploadUI!.setTags([]);
                    this.form.setFields([{name: 'tags', value: []}, {name: 'isProtected', value: false}]);
                    this.resetFeatureFlags();
                } else {
                    this.initProjectFeatureFlags();
                }
            }); 

            this.disposer = () => {
                r1(); r2(); 
            };   
        } else {
            this.disposer = () => { 
                console.log('uploadedPackagesUI is undefined'); 
            };
        }

        this.state = { fileList: [], featureFlagFileList: [], overrideFeatureFlags: false, overridePagesFeatureFlags: false, overrideTextFeatureFlags: false };
    }

    init = () => {
        const { currentProject: project } = this.props.projectUploadUI!;
        if (project) {
            this.action = `${process.env.REACT_APP_MANAGE_URL}projects/${project.id}/portal/upload`;
            this.props.projectUploadUI!.filesList = [];
        }
        this.initProjectFeatureFlags();
    };

    initProjectFeatureFlags () {
        const { currentProject: project } = this.props.projectUploadUI!;

        if (project == null) {
            return;
        }

        console.log(project.title);
        setProjectFeatureFlags(this.props.form, project, featureFlags => {
            setTimeout(() => {
                this.setState({
                    overrideFeatureFlags: featureFlags.overrideFeatureFlags,
                    overridePagesFeatureFlags: featureFlags.overridePagesFeatureFlags,
                    overrideTextFeatureFlags: featureFlags.overrideTextFeatureFlags
                });
            }, 0);
        });
    }

    resetFeatureFlags() {
        resetFormFeatureFlags(this.props.form);
        this.setState({
            overrideFeatureFlags: false,
            overrideTextFeatureFlags: false,
            overridePagesFeatureFlags: false
        });
    }

    componentWillUnmount = () => {
        this.disposer();
    };

    render() {
        this.form = this.props.form!;
        const { tags, currentTags } = this.props.projectUploadUI!;
        const store = this.props.projectUploadUI!;

        const onChange = (info: UploadChangeParam) => {
            let fileList = info.fileList && [...info.fileList];
            this.setState({fileList});
            const status = info.file.status;
            if (status !== 'uploading') {                
                console.log(info.file, info.fileList);
            }

            if (status === 'done') {               
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            } else if (status === 'removed') {
                return;
            }

            store.uploadProgress(info.file);
        };

        const transformFeatureFlags = () => {   
            const obj = {};

            AlphaConfigurations.forEach(c => {
                if (!this.state.overrideFeatureFlags) {
                    this.props.form?.resetFields([c.key]);
                }

                if (this.state.overrideFeatureFlags) {
                    obj['GENERAL::' + c.key] = this.props.form?.getFieldValue(c.key);
                }
                
                if (this.state.overrideTextFeatureFlags && (!this.state.overrideFeatureFlags || 
                    this.props.form?.getFieldValue('TEXT::' + c.key) !== obj['GENERAL::' + c.key])) {
                    obj['TEXT::' + c.key] = this.props.form?.getFieldValue('TEXT::' + c.key);
                }

                if (this.state.overridePagesFeatureFlags && (!this.state.overrideFeatureFlags || 
                    this.props.form?.getFieldValue('IMAGE::' + c.key) !== obj['GENERAL::' + c.key])) {
                    obj['IMAGE::' + c.key] = this.props.form?.getFieldValue('IMAGE::' + c.key);
                }
            });

            // If obj is empty return null.
            return Object.keys(obj).length === 0 ? null : JSON.stringify(obj);
        };

        const featureFlagsPanelExtra = (checked: boolean, onCheckboxChange: (e: CheckboxChangeEvent) => void) => {
            return(
                <div 
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <label>
                        Override flags:
                        <Checkbox 
                            style={{marginLeft: 12}}
                            checked={checked}
                            onChange={onCheckboxChange}
                        />
                    </label>
                </div>
            );
        };

        const beforeDocumentUpload = () => {
            if (this.props.uploadedPackagesUI) {
                this.props.uploadedPackagesUI.setOrderBy(undefined);
                
                if (this.props.uploadedPackagesUI.currentPage !== 0) {
                    this.props.uploadedPackagesUI.setCurrentPage(1);
                }
            }
        };

        const beforeFeatureFlagsUpload = (file: RcFile) => {
            importFeatureFlags(file, this.props.form, featureFlags => {
                this.setState({
                    overrideFeatureFlags: featureFlags.overrideFeatureFlags,
                    overridePagesFeatureFlags: featureFlags.overridePagesFeatureFlags,
                    overrideTextFeatureFlags: featureFlags.overrideTextFeatureFlags
                });
            });

            return false;
        };

        const prepareData = () => {
            return { 
                'tags': currentTags.join(';'), 
                'featureFlags': transformFeatureFlags(),
                'isProtected': this.props.form?.getFieldValue('isProtected') ?? false
            };
        };

        return (
            <div data-id="upload-documents-form">
                <Form form={this.form} layout="vertical" style={{paddingBottom: 20}} initialValues={store.currentTags || []}>
                    <FormItem
                        name="tags"
                        initialValue={currentTags}
                        rules={[{ required: false, message: 'Please select tags!' }]}
                    >
                        <Select placeholder="Tags..." data-id="upload-documents-tag-select" mode="tags" onChange={this.props.projectUploadUI!.setTags}>
                            {tags.map((k, i) => (
                                <Option data-id={`upload-documents-tag-select-${k}`} key={`kw-${i}`} value={k}>{k}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    <div className="feature-flag-import-export-container">
                        <Upload
                            name="file"
                            maxCount={1}
                            fileList={this.state.featureFlagFileList}
                            beforeUpload={beforeFeatureFlagsUpload}
                            onRemove={this.resetFeatureFlags}
                            onChange={info => this.setState({ featureFlagFileList: info.fileList })}
                        >
                            <span className="feature-flag-import-export-button">
                                <i className="alpha-icon xs arrow-down" />
                                <span>Import feature flags</span>
                            </span>
                        </Upload>
                    </div>           
                    <Collapse>
                        <Collapse.Panel 
                            key="1" 
                            header="General feature flags" 
                            forceRender 
                            extra={featureFlagsPanelExtra(this.state.overrideFeatureFlags, (e) => {
                                this.setState({ overrideFeatureFlags: e.target.checked});
                            })}
                        >
                            {this.props.alphaConfigs.map((config) => (
                                <FormItem
                                    key={config.name}
                                    name={config.name}
                                    initialValue={config.value}
                                    label={config.name}
                                >
                                    <Input disabled={!this.state.overrideFeatureFlags} />
                                </FormItem>
                            ))}
                        </Collapse.Panel>
                        <Collapse.Panel 
                            key="2" 
                            header="Text feature flags" 
                            forceRender 
                            extra={featureFlagsPanelExtra(this.state.overrideTextFeatureFlags, (e) => {
                                this.setState({ overrideTextFeatureFlags: e.target.checked});
                            })}
                        >
                            {this.props.alphaConfigs.map((config) => (
                                <FormItem
                                    key={'TEXT::' + config.name}
                                    name={'TEXT::' + config.name}
                                    initialValue={config.value}
                                    label={config.name}
                                >
                                    <Input disabled={!this.state.overrideTextFeatureFlags} />
                                </FormItem>
                            ))}
                        </Collapse.Panel>
                        <Collapse.Panel 
                            key="3" 
                            header="Images feature flags" 
                            forceRender 
                            extra={featureFlagsPanelExtra(this.state.overridePagesFeatureFlags, (e) => {
                                this.setState({ overridePagesFeatureFlags: e.target.checked});
                            })}
                        >
                            {this.props.alphaConfigs.map((config) => (
                                <FormItem
                                    key={'IMAGE::' + config.name}
                                    name={'IMAGE::' + config.name}
                                    initialValue={config.value}
                                    label={config.name}
                                >
                                    <Input disabled={!this.state.overridePagesFeatureFlags} />
                                </FormItem>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                    <HasPermission entityId={store.currentProject?.id} permissionClaim={AppPermissions.CanAccessAdministration}>
                        <FormItem
                            name="isProtected"
                            initialValue={false}
                            valuePropName='checked'
                            style={{paddingTop: 20, marginBottom: 0}}
                        >
                            <Checkbox>Protected</Checkbox>
                        </FormItem>
                    </HasPermission>
                   
                </Form>
                <Dragger
                    data-testid="upload-documents-fileupload"
                    data-id="upload-documents-fileupload"
                    className="alpha-portal-upload-dragger"
                    name="file"
                    onChange={onChange}
                    multiple
                    data={() => prepareData()}
                    style={{ height: '30%' }}
                    action={this.action}
                    fileList={this.state.fileList}
                    beforeUpload={beforeDocumentUpload}
                >
                    <p className="ant-upload-hint">
                        <i className="alpha-icon lg upload-icon"/>
                        Drag and drop or browse file to upload...
                    </p>
                </Dragger>
                
            </div>
        );
    }
}

export default inject(STORE_PROJECT_UPLOAD, STORE_UPLOADED_PACKAGES)(observer(UploadDocuments));